import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/Layout";
import { GameDescription } from "../../components/sections/games/GameDescription";
import { Container } from "../../components/layout/Container";
import { Navigation } from "../../components/navigation/Navigation";
import { HeaderSize } from "../../components/layout/ParallaxHeader";
import { recipeRescuePageData } from "../../content/pageData/games/RecipeRescuePageData";
import { ImageHeader } from "../../components/layout/ImageHeader";
import Img, { FluidObject } from "gatsby-image";
import { GalleryOrientation, GameGallery } from "../../components/sections/games/GameGallery";
import { ContainerColour } from "../../components/layout/DiagonalContainer";
import { Button, ButtonColour, ButtonSize } from "../../components/layout/Button";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        rrHeaderMobile: {
            childImageSharp: any;
        }
        rrHeaderXL: {
            childImageSharp: any;
        }
        rrHeader: {
            childImageSharp: any;
        }
        logo: {
            childImageSharp: any;
        }
        description: {
            childImageSharp: any;
        }
        one: {
            childImageSharp: any;
        }
        two: {
            childImageSharp: any;
        }
        three: {
            childImageSharp: any;
        }
        four: {
            childImageSharp: any;
        }
        lettuce: {
            childImageSharp: any;
        }
        pepper: {
            childImageSharp: any;
        }
        eggplant: {
            childImageSharp: any;
        }
        onion: {
            childImageSharp: any;
        }
        cheese: {
            childImageSharp: any;
        }
        recipePage: {
            childImageSharp: any;
        }
    }
}

export default ({ data }: PageData) => {
    const headerImageMobile = data.rrHeaderMobile.childImageSharp.fluid;
    const headerImageXL = data.rrHeaderXL.childImageSharp.fluid;
    const headerImage = data.rrHeader.childImageSharp.fluid;

    const headerSources = [
        headerImageMobile,
        {
            ...headerImageXL,
            media: `(min-width: 1100px)`,
        },
        {
            ...headerImage,
            media: `(min-width: 768px)`,
        }
    ]

    const images: FluidObject[] = [
        data.one.childImageSharp.fluid,
        data.two.childImageSharp.fluid,
        data.three.childImageSharp.fluid,
        data.four.childImageSharp.fluid,
    ];

    return (
        <Layout
            pageTitle={recipeRescuePageData.metaPageTitle}
            keywords={recipeRescuePageData.metaKeywords}
            description={recipeRescuePageData.metaDescription}
            url={"work/recipe-rescue"}
        >
            <Navigation />
            <ImageHeader size={HeaderSize.LARGE} imageSrc={headerSources}>
                <div className="logo-container">
                    <div className="image-wrapper rr">
                        <Img fluid={data.logo.childImageSharp.fluid} alt={`${recipeRescuePageData.pageTitle} logo`} />
                    </div>
                </div>
            </ImageHeader>
            <Container className="game-description-container">
                <GameDescription
                    gameTitle={"Recipe Rescue"}
                    title={recipeRescuePageData.descriptionTitle}
                    description={recipeRescuePageData.description}
                    links={recipeRescuePageData.links}
                    image={data.description.childImageSharp.fluid}
                    imageAlt={"Nana falling"}
                />
            </Container>
            <GameGallery colour={ContainerColour.RR} images={images} orientation={GalleryOrientation.PORTRAIT}>
                <div className="lettuce">
                    <Img fluid={data.lettuce.childImageSharp.fluid} alt="Green Vegetable" />
                </div>
                <div className="recipePage">
                    <Img fluid={data.recipePage.childImageSharp.fluid} alt="Red Recipe Page" />
                </div>
                <div className="eggplant">
                    <Img fluid={data.eggplant.childImageSharp.fluid} alt="Blue Vegetable" />
                </div>
                <div className="pepper">
                    <Img fluid={data.pepper.childImageSharp.fluid} alt="Red Vegetable" />
                </div>
                <div className="onion">
                    <Img fluid={data.onion.childImageSharp.fluid} alt="Purple Vegetable" />
                </div>
                <div className="cheese">
                    <Img fluid={data.cheese.childImageSharp.fluid} alt="Orange Vegetable" />
                </div>
            </GameGallery>
            <Container className="more-work">
                <Button buttonConfig={{
                    colour: ButtonColour.BLUE,
                    size: ButtonSize.LARGE,
                    ctaLabel: "More Work",
                    ctaLink: "/work",
                    id: "blueGradient",
                    ariaLabel: "View our other work"
                }}
                />
            </Container>
        </Layout>
    );
};


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        rrHeaderMobile: file(relativePath: { eq: "assets/images/work/rr-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 768, maxHeight: 550, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        rrHeaderXL: file(relativePath: { eq: "assets/images/work/rr-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1581, maxHeight: 700, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        rrHeader: file(relativePath: { eq: "assets/images/work/rr-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        logo: file(relativePath: { eq: "assets/images/work/rr/logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        description: file(relativePath: { eq: "assets/images/work/rr/description.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        one: file(relativePath: { eq: "assets/images/work/rr/1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 864, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        two: file(relativePath: { eq: "assets/images/work/rr/2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 418, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        three: file(relativePath: { eq: "assets/images/work/rr/3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 418, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        four: file(relativePath: { eq: "assets/images/work/rr/4.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 418, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        lettuce: file(relativePath: { eq: "assets/images/work/rr/lettuce.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pepper: file(relativePath: { eq: "assets/images/work/rr/pepper.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cheese: file(relativePath: { eq: "assets/images/work/rr/cheese.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        onion: file(relativePath: { eq: "assets/images/work/rr/onion.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        eggplant: file(relativePath: { eq: "assets/images/work/rr/eggplant.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        recipePage: file(relativePath: { eq: "assets/images/work/rr/recipe_page.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`