import React from "react";
import { ButtonColour, ButtonConfig, ButtonSize } from "../../../components/layout/Button";

export const recipeRescuePageData: GamePageData = {
    metaPageTitle: "Recipe Rescue Match-3 Puzzle Game - iDream Interactive",
    metaKeywords: "recipe rescue, recipe rescue game, how to play recipe rescue",
    metaDescription: "Over 2000 levels of fun! Learn more about our chef-tastic match-3 puzzle game available on iOS, Android and Facebook.",
    pageTitle: "Recipe Rescue",
    descriptionTitle: "Match your way through a unique story experience!",
    description: <>
        <p>A delicious new take on the classic match-3 puzzle you love! It’s up to you, and Nana Nutmeg to infuse restaurants with scrumptious new recipes!</p>
        <p>Grab your apron and cook your way through challenging restaurants, and raise to the top! Mix and match ingredients for mouth-watering combinations, and create delectable dishes that leave your customers drooling for more!</p>
    </>,
    links: [
        {
            ctaLabel: "App Store",
            ctaLink: "https://apps.apple.com/ca/app/recipe-rescue/id1002135835",
            externalLink: true,
            colour: ButtonColour.BLUE,
            size: ButtonSize.LARGE,
            id: "appStoreGradient",
            ariaLabel: "Play Recipe Rescue on iOS"
        },
        {
            ctaLabel: "Google Play",
            ctaLink: "https://play.google.com/store/apps/details?id=air.com.idreaminteractive.reciperescue&hl=en_CA&gl=US",
            externalLink: true,
            colour: ButtonColour.BLUE,
            size: ButtonSize.LARGE,
            id: "googlePlayGradient",
            ariaLabel: "Play Recipe Rescue on Android"
        },
        {
            ctaLabel: "Facebook",
            ctaLink: "https://apps.facebook.com/reciperescue",
            externalLink: true,
            colour: ButtonColour.BLUE,
            size: ButtonSize.LARGE,
            id: "facebookGradient",
            ariaLabel: "Play Recipe Rescue on Facebook"
        },
    ]
}

export type GamePageData = {
    metaPageTitle: string,
    metaKeywords: string,
    metaDescription: string,
    pageTitle: string,
    descriptionTitle: string,
    description: JSX.Element,
    links: ButtonConfig[],
}
